<template>
  <div>
    <Breadcrumb
      :home="{ icon: 'pi pi-home', to: '/admin' }"
      :model="BreadcrumbItems"
    />

    <div class="form-card mt-4 ">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="image" class="form-label">
              اللوجو
            </label>
            <img
              :src="body.logo"
              v-if="body.logo"
              class="profile-pic height-150 width-150 align-items-center"
              style="height: 100px;"
            />
            <div class="">
              <i class="ri-pencil-line upload-button"></i>
              <b-form-file
                class="h-100 "
                accept="image/*"
                @change="previewImage"
              ></b-form-file>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="aboutUs" class="form-label">
              نبذه عنا
            </label>
            <textarea
              class="form-control"
              id="aboutUs"
              name="aboutUs"
              rows="4"
              v-model="body.aboutUs"
            ></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="aboutUsYouTube" class="form-label">
              (YouTube)نبذه عنا
            </label>
            <input
              class="form-control"
              id="aboutUsYouTube"
              name="aboutUsYouTube"
              v-model="body.aboutUsYouTube"
            />
          </div>

          <div class="mb-3">
            <label for="aboutUsLink" class="form-label">
              (Link)نبذه عنا
            </label>
            <input
              class="form-control"
              id="aboutUsLink"
              name="aboutUsLink"
              v-model="body.aboutUsLink"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="weHaveText" class="form-label">
              عنا
            </label>
            <textarea
              class="form-control"
              id="weHaveText"
              name="weHaveText"
              rows="2"
              v-model="body.weHaveText"
            ></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="image" class="form-label">
              عنا الصوره
            </label>
            <img
              :src="body.weHavePicture"
              v-if="body.weHavePicture"
              class="profile-pic height-150 width-150 align-items-center"
              style="height: 100px;"
            />
            <div class="">
              <i class="ri-pencil-line upload-button"></i>
              <b-form-file
                class="h-100 "
                accept="image/*"
                @change="previewImage2"
              ></b-form-file>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="address" class="form-label">
              العنوان
            </label>
            <input
              class="form-control"
              id="address"
              name="address"
              v-model="body.address"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="phone" class="form-label">
              الهاتف
            </label>
            <input
              class="form-control"
              id="phone"
              name="phone"
              v-model="body.phone"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="email" class="form-label">
              البريد الإلكتروني
            </label>
            <input
              class="form-control"
              id="email"
              name="email"
              v-model="body.email"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="fB" class="form-label">
              الفيس بوك
            </label>
            <input class="form-control" id="fB" name="fB" v-model="body.fB" />
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="twitter" class="form-label">
              تويتر
            </label>
            <input
              class="form-control"
              id="twitter"
              name="twitter"
              v-model="body.twitter"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="youTube" class="form-label">
              يوتيوب
            </label>
            <input
              class="form-control"
              id="youTube"
              name="youTube"
              v-model="body.youTube"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="tikTok" class="form-label">
              تيك توك
            </label>
            <input
              class="form-control"
              id="tikTok"
              name="tikTok"
              v-model="body.tikTok"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="insta" class="form-label">
              انستا
            </label>
            <input
              class="form-control"
              id="insta"
              name="insta"
              v-model="body.insta"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="whatsapp" class="form-label">
              وتساب
            </label>
            <input
              class="form-control"
              id="whatsapp"
              name="whatsapp"
              v-model="body.whatsapp"
            />
          </div>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="update()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      BreadcrumbItems: [{ label: 'تعديل' }],
      body: {
        logo: null,
        aboutUs: null,
        aboutUsYouTube: null,
        aboutUsLink: null,
        weHaveText: null,
        weHavePicture: null,
        address: null,
        phone: null,
        email: null,
        fB: null,
        twitter: null,
        youTube: null,
        tikTok: null,
        insta: null,
        whatsapp: null,
      },
      id: null,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.logo = image;
      });
    },

    previewImage2(ev) {
      this.$file2base64(ev, (image) => {
        this.body.weHavePicture = image;
      });
    },

    update() {
      if (this.body.logo) {
        if (this.body.logo.startsWith('http')) {
          delete this.body.logo;
        }
        this.$http.post(`home`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`home`).then(
      (res) => {
        this.body = res.data.data;
        this.body.logo = this.$baseUploadURL + this.body.logo;
        this.body.weHavePicture = this.$baseUploadURL + this.body.weHavePicture;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );
  },
};
</script>
